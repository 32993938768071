@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Hauora";
  src: local("Hauora"), url(./fonts/ClashGrotesk-Light.woff) format("woff");
}

@font-face {
  font-family: "PressStart2P";
  src: local("PressStart2P"), local("PressStart2P"),
    url("./fonts/PressStart2P.ttf");
}

#hero {
  position: relative;
}

#hero:before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;

  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

.header {
  position: absolute;
}
